import React from "react";
import Layout from '../components/layout';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const aboutPage = () => (
	<Layout >
		<Row style={{paddingBottom : '50px'}}>
			<Col >
				<h1>About Us</h1>
				<p>Threesons is an ISO 9001-2000 certified company with fully automated art of manufacturing at Bommasandra – near the IT hub, Bangalore. Manufacturing decorative and industrial paints, construction chemicals, epoxy chemicals and supplying all kinds of industrial chemicals. </p>
			</Col>
		</Row> 
		<hr />
		<Row style={{paddingBottom : '50px'}}>
			<Col>
			<p><strong>Chemical division: </strong>Supplies industrial chemicals, solvents and pigments to public and private sector industries for more than three decades. </p>
			</Col>
			
		</Row>

		<Row style={{paddingBottom : '50px'}}>
			<Col>
			<p><strong>Paint division: </strong>Manufacturing domestic and industrial paints, metallic emulsion, zinc rich paints, wood primers, weather proof exterior emulsion, interior emulsion, N.C sanding sealer, filler, thinner and weather proof acrylic M.P tile coat emulsion in a wide range of attractive color shades.</p>
			</Col>
		</Row>

		<Row style={{paddingBottom : '50px'}}>
			<Col>
			<p><strong>Construction chemical division: </strong>Provides durable, easy to use, innovative products for civil construction industry.</p>
			</Col>
		</Row>

		<Row style={{paddingBottom : '50px'}}>
			<Col>
			<p><strong>Epoxy Division: </strong>Epoxy division is involved in the manufacturing & marketing of specialty resins and chemicals for the treatment of Granite, Marble and other stones.</p>
			</Col>
		</Row>
		</Layout>
)

export default aboutPage;